.doc-editor {
  .header {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 1.6rem 2.4rem;

    .btn-back {
      padding-left: 0;
    }

    .info-card {
      background: #FAFAFA;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      padding: 0.1rem 0.8rem;
      margin-left: 1.2rem;
    }
    .mode-select {
      min-width: 132px;
      outline: none;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      padding: 0 1rem;
      height: 32px;
      &:active {
        outline: none;
      }

      .select {
        padding: 0.4rem 1rem;
        color: #0033CC;
      }
      &.zoom{
        min-width: 80px;
        margin-right: 8px;
      }
    }
  }

  .comment-sec {
    border: 1px solid #F0F0F0;
    border-radius: 2px;
    background-color: #FFF;
    width: 0;
    transition: all 0.4s ease-in-out;

    &.show {
      width: 24%;
      padding: 2.4rem 0 1rem 2.4rem;
    }

    .profile-pic {
      width: 34px;
      height: 34px;
      object-fit: contain;
      margin-right: 0.8rem;
    }
    .font-input{
      margin: 0 12px 0 0;
      width: 165px;
      .label-cont{
        min-width: 50px;
      }
    }
    .comment {
      padding: 1.6rem 0;
      padding-right: 1rem;

      .user {
        margin-top: 0.4rem;
      }

      .comment-text {
        text-align: justify;
        margin: 0.4rem 3.6rem 0 0;
        word-break: break-all;
      }
      .btn-mark{
        width: 100px;
        padding: 0;
        margin: 4px 0;
      }
      .marked{
        margin: 2px 0;
        .icon{
          margin-right: 8px;
        }
      }
    }

    .new-comment {
      margin-top: 1.6rem;
      .textarea-cont{
        margin-right: 12px;
      }
      .textarea {
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        padding: 0.8rem 1.2rem;
        margin-top: 0.4rem;
        resize: none;
        &:focus {
          outline: none;
        }
      }
      .count-row{
        margin-top: 8px;
      }
      .count {
        text-align: right;
      }
    }

    .btn-add {
      width: fit-content;
      padding: 0.8rem 1rem;
    }
  }

  .document-sec {
    padding: 4.8rem 4.8rem 0 4.8rem;
  }

  .logo-sec {
    position: relative;
    border-bottom: 1px solid #777;
    margin-bottom: 0.8rem;
  }
  .btn-unsaved{
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
    border: 1px solid #D9D9D9;
  }
  .no-internet-alert{
    position: absolute;
    z-index: 2;
    background-color: rgba(136, 136, 136, 0.8);
    .box{
      padding: 2px 8px;
      background-color: #FFDDDD;
    }
    .warning{
      width: 48px;
      height: 48px;
      margin-right: 8px;
      .icon-warning-icon{
        font-size: 4.4rem;
      }
    }
    .subtitle{
      margin: 0;
    }
  }
}
.download-overlay{
  background-color: rgba(70, 62, 62, 0.85);
  .btn{
    margin-top: 24px;
  }
  .btn:first-child{
    margin-right: 24px;
  }
}