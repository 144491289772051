.docs-header {
  padding: 2.2rem 2.6rem;
}
.home-create{
  .hint{
    text-align: left;
    color: #808080;
    margin-bottom: 0.8rem;
  }
  .btn{
    margin-right: 1.6rem;
  }
  .oval{
    max-width: 40rem;
    max-height: 40rem;
    object-fit: contain;
    margin-left: 1.6rem;
  }
}
.home-docs{
  
}