.create-doc {
  width: 32vw;

  .label-cont {
    width: 90px;
  }

  .content {
    padding: 1.6rem 0 0 0;
  }

  .text-input {
    .label-cont {
      width: 90px;
    }
  }

  &.all {
    width: 52vw !important;

    .content {
      width: 100% !important;
    }
  }
}

.archive-docs {
  width: 360px;

  .title-sec {
    color: #FE3333;
  }

  .warn {
    line-height: 1.6;
  }

  .label-cont {
    display: none;
  }

  .field-input {
    width: 60%;
    .container{
      border-radius: 8px;
    }
    .input {
      font-weight: bold;
    }
  }
  .warning{
    width: 3.4rem;
    height: 3.4rem;
    margin-right: 12px;
  }
}
.delete-docs{
  width: 450px;
}

.export-docs {
  width: 38vw !important;

  .content {
    width: 95%;
  }

  .hints {
    padding-left: 10rem;
    margin-bottom: 1.2rem;
    margin-top: 5px;
  }

  .font-input {
    margin-bottom: 0;

    .label {
      width: 8.5rem;
    }
  }
}
.reset-trans-doc{
  width: 580px;
  .content{
    // width: 95%;
    padding: 8px 0 0 0 ;
  }
  .dialog-actions{
    // padding-top: 0;
    box-shadow: none;
  }
}
.create-loading {
  .loader {
    max-width: 632px;
    max-height: 540px;
    object-fit: contain;
  }
}

.upload-doc-dialog {
  padding: 2.4rem;
  width: 45vw;

  .title-sec {
    padding: 0 0 2.4rem 0;
  }

  .files-drop-zone {
    width: 65%;
    padding: 2.4rem;
    background: #FAFAFA;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    margin-bottom: 1rem;
    cursor: pointer;

    .icon {
      margin-bottom: 2.4rem;
      font-size: 4rem;
    }

    .text {
      line-height: 2.4rem;
    }
  }

  .file-card {
    padding: 0.8rem 1.2rem;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    margin-bottom: 0.8rem;

    .icon {
      font-size: 4rem;
      margin-right: 1.6rem;
    }

    .btn-delete {
      box-shadow: none;
    }

    .doc-input {
      flex: 2;
      margin-right: 0.8rem;
    }

    .font-input {
      margin-bottom: 0;
      border: 1px solid #D9D9D9;
      border-radius: 2px;
      background: #FAFAFA;
      padding: 2px 4px;

      .container {
        background-color: #FFF;
        &.disabled{
          background-color: #EFEFEF;
          cursor: not-allowed;
        }
      }
    }
    &.non-file{
      padding: 0;
      border: 0;
      .font-input{
        background-color: transparent;
        border: 0; 
      }
    }
  }

  .file-form {
    padding: 1.6rem 0.4rem;

    .font-input {
      margin-bottom: 0;
    }
  }
  
}

.send-dialog {
  width: 30vw;

  .title-sec {
    padding-left: 0;
  }

  .content {
    width: 95%;
    padding: 1.2rem 5rem 2.4rem;
  }
}

.update-history {
  width: 40vw;

  .font-input .label {
    margin-right: 8px;
  }

  .content {
    padding-top: 1.6rem;
    width: 99%;
  }
}

.warning {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 4px;
}
.discard-modal{
  width: 25vw;
  .dialog-actions{
    box-shadow: none;
  }
}
.export-docs-title{
  font-weight: 600;
  color: #238787;
  font-size: 20px!important;
}
.caution-text {
  margin-left: 54px;
}
.checkbox {
  margin-left: -70px!important;
}
.checkbox-text {
  margin-left: 10px;
}
.export-dialog-content {
  margin-left: 25px;
  margin-top: 25px;
}