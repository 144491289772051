.doc-stats{
  .header{
    padding: 1.6rem 2.4rem;
    .btn-back{
      padding-left: 0;
    }
  }
  .content{
    padding: 1rem 6.7rem 1rem 8rem;
    .stats-card{
      width: 17.5rem;
      height: 12.3rem;
      margin-right: 3.2rem;
      margin-bottom: 1rem;
      border: 1px solid #1E686D;
      border-radius: 2px;
      padding: 2rem 2.4rem;
      .num{
        line-height: 5rem;
      }
    }
    .doc-stats-table{
      .btn-export{
        padding-right: 0;
        .label{
          margin-right: 0.8rem;
        }
        .icon-text{
          margin-right: 0;
        }
      }
      .title-sec{
        padding: 1rem 1.6rem;
      }
      .table-container{
        border: 1px solid rgba(30, 104, 109, 1);
        border-radius: 2px;
        background-color: #FFF;
      }
      .table{
        border: none;
        border-spacing: 0;
        .cell{
          padding: 0.8rem;
          border-top: 1px solid rgba(30, 104, 109, 1);
        }
        .head-cell{
          padding: 0.8rem;
        }
      }
    }
    .logo{
      width: 200px;
      height: 40px;
      // display: none;
    }
  }
}