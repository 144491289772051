.form-dialog{
  background: #FFFFFF;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  width: 54%;
  padding: 2.4rem;
  max-width: unset !important;
  margin: 0 !important;
  .title-sec{
    padding: 0;
  }
  .dialog-container{
    padding: 0;
  }
  .dialog-actions{
    padding: 2rem 0 0 0;
    box-shadow: inset 0px 1px 0px #F0F0F0;
  }
}
.transparent-dialog{
  max-width: unset !important;
  background-color: transparent !important;
  box-shadow: none !important;
}