.font-input {
  margin-bottom: 16px;
  .error{
    margin: 0 4px 0 4px;
  }
  &.radio-cont{
    .container{
      border: none;
      padding-left: 0;
    }
    .hint{
      margin-top: 4px;
    }
    .label-cont{
      margin-top: 8px;
    }
  }
  &.readOnly .label::after{
    display: none;
  }
  .label{
    padding-right: 1rem;
  }
  .label-cont{
    min-width: 72px;
  }
  .colon{
    margin-right: 1.6rem;
  }
  .container{
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    padding: 4px 12px;
    &.readOnly{
      border: none;
    }
    &.disabled{
      cursor: not-allowed;
      background-color: #EFEFEF;
    }
    &.disabled.readOnly{
      background-color: transparent;
    }
  }
  .input{
    border: none;
    outline: none;
    line-height: 2.2rem;
    width: 100%;
    resize: none;
    background-color: transparent;
    margin-top: 0;
  }
  &.input-col, &.column{
    .label{
      margin-bottom: 0.4rem;
    }
  }
  &.req{
    .label{
      position: relative;
    }
    .label::after{
      content: '*';
      color: rgb(244, 67, 54);
      position: absolute;
      font: inherit;
      top: 0;
      right: 0;
      margin-left: 2px;
    }
  }
  .count{
    text-align: right;
  }
}
.dropdown-list{
  .option[aria-selected="true"]{
    background-color: transparent !important;
    color: #0033CC;
  }
}
.dropdown{
  border: 1px solid #D9D9D9;
  .icon{
    margin-right: 0.8rem;
  }
  &:focus, &:hover{
    outline: none !important;
    border: 1px solid #D9D9D9;
  }
}
.dropdown-item{
  .icon, .label{
    color: inherit;
  }
  .icon{
    margin-right: 0.8rem;
  }
  &.Mui-selected{
    background-color: transparent !important;
  }
  &.primary:hover{
    background-color: #fff !important;
    .icon, .label{
      color: #0033CC;
    }
  }
}