@import "./font.scss";
@import "./icon.scss";
* {
  // color: #4f4f4f;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box !important;
}
.col {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
}

.row {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.row-reverse{
  flex-direction: row-reverse;
}
.v-ctr{
  align-items: center;
}
.h-ctr{
  justify-content: center;
}
.v-start {
  align-items: flex-start;
}
.v-end {
  align-items: flex-end;
}
.h-btn {
  justify-content: space-between;
}
.h-arnd {
  justify-content: space-around;
}
.h-end {
  justify-content: flex-end;
}
.flex-full,
.f-rest{
  flex: 1;
}
.f-rest-2{
  flex: 2;
}
.o-hide{
  overflow: hidden;
}
.oy-auto{
  overflow: hidden auto;
}
.btn{
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
body{
  width: 100vw;
  height: 100vh;
  margin: 0;
}
.ant-app{
  font-size: inherit;
  font-family: 'Roboto';
  color: inherit;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.screen{
  background-color: #fff;
  position: relative;
}
.cFFF{
  color: #FFF;
}
.cFE3333{
  color: #FE3333;
}
.c0133CC{
  color: #0133CC;
}
.c1155CC{
  color: #1155CC;
}
.c00085{
  color: rgba(0, 0, 0, 0.85);
}
.c00045{
  color: rgba(0, 0, 0, 0.45);
}
.c00025{
  color: rgba(0, 0, 0, 0.25);
}
.c000{
  color: #000;
}
.cBFBFBF {
  color: #BFBFBF;
}
.bgOverall{
  background-color: #1E686D;
}
.c1E686D {
  color: #1E686D;
}
.status.published{
  background-color: rgb(34, 136, 34);
}
.status.draft{
  background-color: rgb(187, 34, 34);
}
.status.reviewed{
  background-color: rgb(255,85, 34);
}
.status.approved{
  background-color: rgb(68, 187, 68);
}
.status.archived{
  background-color: rgb(136, 136, 136);
}

.classification.confidential{
  background-color: #871211;
}
.classification.personal{
  background-color: #871211;
}
.classification.restricted{
  background-color: #FE7733;
}
.classification.internal{
  background-color: #FEFE77;
}
.classification.public{
  background-color: #33CC77;
}
.c44bb44{
  color: #44bb44;
}
.c00FFFF{
  color: #00FFFF;
}
.c777{
  color: #777;
}
.cinherit{
  color: inherit;
}
.c0033CC{
  color: #0033CC;
}
.bg-primary {
  background-color: #0133CC;
}
.bg238787{
  background-color: #238787;
}
.bgCFE3333{
  background-color: #FE3333;
}
.bgDDFFFF{
  background-color: #DDFFFF;
}
.c238787{
  color: #238787;
}
.cc0000{
  color: #cc0000;
}
.c999{
  color: #999;
}
.caps{
  text-transform: capitalize;
}
.tup{
  text-transform: uppercase;
}
.no-caps{
  text-transform: initial;
}
.text-center{
  text-align: center;
}
.txt-justify {
  text-align: justify;
}
.txt-left {
  text-align: left;
}
.api-progress{
  visibility: hidden;
  &.show{
    visibility: visible;
  }
}
.cke_float{
  max-width: calc(21cm - 12rem);
}
.soft-hide{
  opacity: 0;
}
.btn-menu{
  border: 1px solid #D9D9D9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 2px;
  margin-left: 1rem;
  width: 32px;
  height: 32px;
  padding: 0;
  .icon-dot{
    // font-size: 3px;
  }
}
.cke_toolbar {
  font-size: 14px;
  text-align: justify;
  .cke_readonly,
  .cke_editable{
    * {
      box-sizing: unset !important;
      font-family: unset;
      // word-wrap: break-word;
    }
    p{
      margin-top: 0;
      margin-block-start: 0;
      // margin-block-end: 0;
      // margin-bottom: 2px;
    }
  }
  p:first-child{
    // display: none;
  }
  &.tbl_of_content{
      p {
        width: 100%;
        clear: both;
        span:nth-child(2){
          float: right;
        }
      }
  }
}